.wrapper {
  position: relative;
  padding: var(--space-xxl) 0;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -150px;
    left: -100px;
    width: calc(100% + 200px);
    height: 600px;
    max-height: 70vh;
    background-color: var(--grey-shade-800);
  }
  .content {
    position: relative;
    z-index: 1;
    .text {
      margin-bottom: var(--space-xl);
    }
  }
}
