.wrapper {
  padding: var(--space-md) var(--space-lg);

  .title {
    margin-bottom: var(--space-md);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 0 var(--space-md);
    img {
      max-width: 70px;
    }
    .content {
      .row {
        display: flex;
        align-items: center;
        gap: 0 var(--space-esm);
      }
    }
  }

  .conditions {
    .row {
      display: flex;
      align-items: center;
      gap: 0 var(--space-esm);
      margin-bottom: var(--space-md);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
