.wrapper {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .input {
      padding-right: var(--space-sm);
      max-width: 40%;
      width: 350px;
    }
    .actions {
      padding-left: var(--space-sm);
      width: 60%;
      display: flex;
      justify-content: flex-end;
      > * {
        margin-right: var(--space-md);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .body {
    margin-top: var(--space-md);
  }
}
