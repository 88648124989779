.row {
  padding: var(--space-md) var(--space-lg);
  gap: 1rem;
  display: flex;
  flex-direction: column;

  > * {

    border: 1px solid var(--grey-shade-200);
    border-radius: var(--border-radius-md);
  }
}