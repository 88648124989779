.title {
  margin: var(--space-lg) 0;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  border-top: 1px solid var(--grey-shade-200);
  padding-top: 1rem;
}

.details {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-lg);
  .detail {
    width: calc(33% - 20px);
  }
}
