.wrapper {
  padding: var(--space-md) var(--space-lg);

  .header {
    display: flex;
    align-items: center;
    gap: 0 var(--space-md);
    padding-bottom: var(--space-esm);
    .img {
      max-width: 40px;
    }
    .content {
      .row {
        display: flex;
        align-items: center;
        gap: 0 var(--space-esm);
      }
    }
  }

  .detail {
    .row {
      display: flex;
      align-items: center;
      gap: 0 var(--space-md);
      &:nth-child(2) {
        padding: var(--space-xl);
        border-left: 2px dashed var(--grey-shade-200);
        position: relative;
        transform: translateX(6px);
        &::after,
        &::before {
          content: "";
          width: 10px;
          height: 10px;
          background-color: var(--grey-shade-200);
          display: block;
          position: absolute;
          left: -6px;
          border-radius: 50%;
        }
        &::after {
          top: -10px;
        }
        &::before {
          bottom: 0;
        }
      }
    }
  }
  .layoverRow {
    padding: var(--space-xl) 0 0;
    .layoverText {
      color: var(--red-shade);
    }
  }
}
