.destinations {

    background-color: #F9FAFB;
    border-radius: 10px;
    border: 1px solid #D1D5DB;
    padding: 1rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    h2 {
        font-size: 1.5rem;
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
            margin-bottom: 1rem;
        }
    }

    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }

    .number {
        color: #F26B52;
        font-size: 1.2rem;
        font-weight: 700;
    }

    .right {
        text-align: end;
        gap: 1rem;
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 996px) {
    .destinations {
        width: 32%;
    }
}

@media (max-width: 995px) {
    .destinations {
        width: 100%;
    }
}