.group {
  .title {
    margin-bottom: var(--space-md);
  }
  .content {
  }
}

.profile {
  display: flex;
  gap: var(--space-lg);
  align-items: center;
  .image {
    position: relative;
    .btn {
      width: 1.8rem;
      height: 1.8rem;
      padding: 0;
      margin: 0;
      border-radius: 50%;
      background-color: #fff;
      border: 0;
      box-shadow: 0px 6px 12px 0px rgba(65, 65, 65, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 7px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7857 4.00001L19.5 7.71429L8.71429 19.7143H5V16L15.7857 4.00001Z' stroke='%231F2937' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      input {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        z-index: 111;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
