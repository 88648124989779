.label {
  color: var(--grey-shade-800);
  font-size: 0.667rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-bottom: 4px;
  &.title {
    font-weight: 600;
    font-size: 0.889rem;
    line-height: 1.4;
    margin-bottom: var(--space-sm);
  }
}
