.wrapper {
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .centerBox {
    width: 600px;
    margin: 0 auto;
  }
}
