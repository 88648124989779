.formGroup {
  margin-bottom: var(--space-md);
  &.small {
    margin-bottom: var(--space-sm);
  }
  &.regular {
    margin-bottom: var(--space-md);
  }
  &.medium {
    margin-bottom: var(--space-md);
  }
  &.large {
    margin-bottom: var(--space-lg);
  }
  &.noMargin {
    margin: 0;
  }
}
