.chartCard {
    gap: 1rem;
    background-color: #f9fafb;
    border-radius: 10px;
    border: 1px solid #d1d5db;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        button {
            height: fit-content;
        }
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        margin-inline-start: 1rem;
        color: #f26b52;
        font-weight: 700;
        display: flex;
        align-items: center;

        span {
            font-size: 14px;
            color: black;
            margin-inline-start: 0.5rem;
        }
    }

    canvas {
        width: 100%;
        height: auto !important;
    }
}

@media (min-width: 996px) {
    .chartCard {
        width: 32%;
    }
}

@media (max-width: 995px) {
    .chartCard {
        width: 100%;
    }
}