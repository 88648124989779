.basicInfo {
  padding: var(--space-md) var(--space-lg);
  display: flex;
  .image {
    padding-right: var(--space-xl);
  }
  .data {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - var(--space-xl) - 4.667rem);
    gap: var(--space-md) 0;
    .list {
      width: 50%;
    }
  }
}
