.linkWrapper {
  margin-bottom: var(--space-md);
  .link {
    padding: var(--space-md);
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    border-radius: var(--border-radius-sm);
    transition: all 0.4s ease 0s;
    img {
      margin-right: var(--space-esm);
      transition: all 0.4s ease 0s;
    }
    .title {
      transition: all 0.4s ease 0s;
    }
    &.active {
      background-color: var(--primary-color);
      .title {
        color: #fff !important;
      }
    }
    &:hover {
      &:not(.active) {
        .title {
          transform: translateX(3px);
        }
      }
    }
  }
}
