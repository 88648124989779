.row {
  display: flex;
  align-items: center;
  gap: 0 var(--space-sm);
  .input1 {
    width: 33%;
  }
  .input2 {
    width: 33%;
  }
  .input3 {
    width: 34%;
  }
  .delete {
    cursor: pointer;
  }
}
