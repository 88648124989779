.dropdown {
  width: 120%;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 14px));
  background: #ffffff;
  border: 1px solid var(--grey-shade-200);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius-sm);
  padding: var(--space-esm) 0;
  .link {
    width: 100%;
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    text-decoration: none;
    padding: var(--space-sm) var(--space-md);
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      margin-right: var(--space-esm);
    }
    &:hover {
      background-color: var(--grey-shade-100);
    }
  }
  .logout {
    margin-top: var(--space-esm);
    padding-top: var(--space-esm);
    border-top: 1px solid var(--grey-shade-300);
  }
  .detail {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: var(--space-md);
    margin-bottom: var(--space-md);
    .img {
      margin-bottom: var(--space-esm);
    }
  }
}
