.wrapper {
  margin-bottom: var(--space-md);

  &.clickable {
    cursor: pointer;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-lg);

    .left {
      display: flex;
      align-items: center;

      .outOfPolicy {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin: 0 var(--space-esm);
        }

        .policyText {
          color: var(--red-shade);
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 0 var(--space-esm);

      button {
        border: 0;
        margin: 0;
        padding: 0;
        display: inline-block;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 var(--space-sm);
    padding-top: var(--space-md);
    border-top: 1px solid var(--grey-shade-200);

    .text {
      display: flex;
      align-items: center;
      gap: 0 5px;
    }

    .price {
      display: flex;
      align-items: center;
      gap: 0 var(--space-sm);
    }
  }
}

.details {


  .flightRow {
    display: flex;
    margin-bottom: var(--space-lg);

    .logo {
      width: 15%;

      img {
        width: 3.111rem;
        height: 3.111rem;
        object-fit: cover;
      }
    }

    .flight {
      width: 85%;
    }

    .otherDetails {
      width: 0;
      text-align: right;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .luggage {
        display: flex;
        align-items: center;
        margin-bottom: var(--space-esm);

        img {
          margin-left: var(--space-esm);
          width: 0.889rem;
        }
      }
    }
  }
}

.row {
  margin-bottom: var(--space-md);
  border: none !important;

  .flightRow {
    margin-bottom: 0;
  }
}

.traveler {
  display: flex;
  align-items: center;
  gap: 0 var(--space-esm);
}

.policyRow {
  margin-bottom: var(--space-sm);
}

.labelWithDetail {
  display: flex;
  width: 100%;
  margin-bottom: var(--space-md);

  >div {
    width: 50%;
  }
}