.color {
  width: 1.333rem;
  height: 1.333rem;
  border-radius: 4px;
  border: 4px solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    max-width: 10px;
  }
}
