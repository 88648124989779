.loadingBar {
    width: 0;
    height: 4px;
    background: #f26b52;
    animation: loading 2s linear forwards;

    @keyframes loading {
        from {
            width: 0;
        }

        to {
            width: 100%;
        }
    }
}