.table {
  width: 100%;
  border-spacing: 0;
  thead {
    background: var(--grey-shade-100);
    tr {
      th {
        padding: 10px var(--space-esm);
        text-align: left;
        &:first-child {
          border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
          padding-left: var(--space-md);
          width: 50px;
        }
        &:last-child {
          border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
          padding-right: var(--space-md);
        }
      }
    }
  }
  tbody {
    tr {
      cursor: pointer;
      td {
        padding: var(--space-md) var(--space-esm);
        border-bottom: 1px solid var(--grey-shade-200);
        font-weight: 400;
        font-size: 0.778rem;
        line-height: 1.4;
        color: var(--grey-shade-800);
      }
      .showOnHover {
        opacity: 0;
      }
      &.selected {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
      }
      &:hover {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        .showOnHover {
          opacity: 1;
        }
      }
    }
  }
}
