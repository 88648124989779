.message {
  padding-left: 2.333rem;
  border-radius: var(--border-radius-sm);
  background-repeat: no-repeat;
  background-position: center left 0.556rem;
  background-size: 1.333rem;
  display: flex;
  align-items: center;
  gap: 0 var(--space-md);
  padding: var(--space-md);
  padding-left: 2.333rem;
  position: fixed;
  top: var(--space-lg);
  right: var(--space-lg);
  z-index: 11111111111;
  width: 327px;
  max-width: 100%;
  transform: translateX(200%);
  transition: all 0.4s ease 0s;
  border: 1px solid;
  background-color: #fff;
  &.active {
    transform: none;
  }
  .close {
    margin-left: auto;
    cursor: pointer;
  }
  &.success {
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.0115 11.2065L13.6822 18.2065C13.4628 18.4946 13.1233 18.665 12.7617 18.6673H12.7535C12.3953 18.6673 12.057 18.5017 11.8353 18.2193L8.99798 14.5945C8.60132 14.0882 8.68998 13.3543 9.19748 12.9577C9.70382 12.5598 10.4388 12.6485 10.8355 13.1572L12.7407 15.5908L17.1553 9.79365C17.545 9.28148 18.2765 9.18115 18.791 9.57198C19.3032 9.96282 19.4023 10.6943 19.0115 11.2065ZM14.0007 2.33398C7.55715 2.33398 2.33398 7.55715 2.33398 14.0007C2.33398 20.443 7.55715 25.6673 14.0007 25.6673C20.4442 25.6673 25.6673 20.443 25.6673 14.0007C25.6673 7.55715 20.4442 2.33398 14.0007 2.33398Z' fill='%2349BF26'/%3E%3C/svg%3E%0A");
    border-color: var(--green-shade);
    box-shadow: 0px 6px 12px 0px rgba(73, 191, 38, 0.1);
  }
  &.error {
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.1578 15.5082C17.614 15.9643 17.614 16.7017 17.1578 17.1578C16.9303 17.3853 16.6317 17.4997 16.333 17.4997C16.0343 17.4997 15.7357 17.3853 15.5082 17.1578L13.9997 15.6493L12.4912 17.1578C12.2637 17.3853 11.965 17.4997 11.6663 17.4997C11.3677 17.4997 11.069 17.3853 10.8415 17.1578C10.3853 16.7017 10.3853 15.9643 10.8415 15.5082L12.35 13.9997L10.8415 12.4912C10.3853 12.035 10.3853 11.2977 10.8415 10.8415C11.2977 10.3853 12.035 10.3853 12.4912 10.8415L13.9997 12.35L15.5082 10.8415C15.9643 10.3853 16.7017 10.3853 17.1578 10.8415C17.614 11.2977 17.614 12.035 17.1578 12.4912L15.6493 13.9997L17.1578 15.5082ZM13.9997 2.33301C7.56667 2.33301 2.33301 7.56667 2.33301 13.9997C2.33301 20.4327 7.56667 25.6663 13.9997 25.6663C20.4327 25.6663 25.6663 20.4327 25.6663 13.9997C25.6663 7.56667 20.4327 2.33301 13.9997 2.33301Z' fill='%23EF4D56'/%3E%3C/svg%3E%0A");
    border-color: var(--red-shade);
    box-shadow: 0px 6px 12px 0px rgba(239, 77, 86, 0.1);
  }
  p {
    word-break: break-all !important;
  }
}
