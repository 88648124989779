.horizontal-slider {
  width: 100%;
  height: 30px;
}

.vertical-slider {
  height: 380px;
  width: 50px;
}

.example-thumb {
  font-size: 0.9em;
  text-align: center;
  //   background-color: black;
  color: white;
  cursor: pointer;
  border: 2px solid gray;
  box-sizing: border-box;
}

.example-thumb.active {
  //   background-color: grey;
}

.example-track {
  position: relative;
  background: var(--grey-shade-300);
}

.example-track.example-track-1 {
  background: var(--primary-color);
}

.example-track.example-track-2 {
  background: var(--grey-shade-300);
}

.example-mark {
  width: 8px;
  height: 8px;
  border: 2px solid #000;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.horizontal-slider .example-track {
  top: 5px;
  height: 4px;
  border-radius: 2px;
  max-width: 100%;
}

.horizontal-slider .example-thumb {
  top: 0;
  width: 32px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 2px;
  background-color: #fff;
  color: var(--grey-shade-800);
  border: 1px solid var(--grey-shade-200);
  transform: translateY(-100%);
  &::before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 0;
    border-radius: 50%;
    background-color: #fff;
    border: 3px solid var(--primary-color);
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    transform: translateY(100%);
  }
}

.horizontal-slider .example-mark {
  margin: 0 calc(25px - 6px);
  bottom: calc(50% - 6px);
}

.vertical-slider .example-thumb {
  left: 1px;
  width: 48px;
  line-height: 40px;
  height: 50px;
}

.vertical-slider .example-track {
  left: 20px;
  width: 10px;
}

.vertical-slider .example-mark {
  margin: calc(25px - 6px) 0;
  left: calc(50% - 6px);
}
