.navigation {
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-left: 1.778rem;
      &:first-child {
        margin-left: 0;
      }
      a {
        font-weight: 500;
        font-size: 0.667rem;
        line-height: 1.333;
        text-decoration: none;
        color: var(--text-color);
      }
      .subList {
        display: none;
      }
      &.hasLinks {
        a {
          background-repeat: no-repeat;
          background-position: right center;
          padding-right: var(--space-lg);
          background-image: var(--dropdown-icon);
        }
      }
    }
  }
  &.darkBG {
    ul {
      li {
        a {
          color: var(--white-shade);
        }
        &.hasLinks {
          a {
            background-image: var(--dropdown-icon-white);
          }
        }
      }
    }
  }
}
