.inputBox {
  position: relative;
  min-width: 1.334rem;
  min-height: 1.334rem;
  input,
  .checkbox {
    width: 1.334rem;
    height: 1.334rem;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    transition: all 0.4s ease 0s;
  }
  input {
    margin: 0;
    z-index: 1;
    opacity: 0;
    &:checked ~ {
      .checkbox {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.76471 5.49L4.87471 8.6L10.4767 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  .label {
    padding-left: 1.778rem;
    font-weight: 600;
    font-size: 0.667rem;
    line-height: 1.5;
    color: var(--grey-shade-700);
    display: inline-block;
  }
  .checkbox {
    border-radius: var(--border-radius-sm);
    border: 2px solid var(--grey-shade-200);
  }
  &.primary {
    input {
      &:checked ~ .checkbox {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
  }
}
