.wrapper {
  padding: var(--space-md) var(--space-xl);
  .heading {
    display: flex;
    align-items: center;
    gap: var(--space-esm);
    margin-bottom: var(--space-md);
  }
  .header {
    display: flex;
    gap: 8px;
    position: relative;
    .dropDown {
      position: absolute;
      right: 0;
      cursor: pointer;
      transform: rotate(180deg);
      transition: all 0.4s ease 0s;
    }
  }
  .body {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-md) 0;
    padding-top: var(--space-sm);
    .list {
      width: 50%;
      padding-right: var(--space-esm);
      &.full {
        width: 100%;
      }
      .flights {
        display: flex;
        gap: var(--space-esm);
      }
    }
  }

  .open {
    .dropDown {
      transform: rotate(0);
    }
  }
}

.travelerList {
  width: 100%;
  .list {
    display: flex;
    align-items: center;
    gap: 0 var(--space-esm);
    width: calc(100% - 3rem);
    margin-bottom: var(--space-esm);
    &:last-child {
      margin-bottom: 0;
    }
    .img {
      width: 2rem;
      height: 2rem;
    }
  }
}
