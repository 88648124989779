.title {
  margin: var(--space-md) 0;
}

.table {
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-md);

    &.total {
      padding-top: var(--space-md);
      border-top: 1px solid var(--grey-shade-200);
    }

    .value {
      text-align: right;
    }
  }
}

.btn {
  // margin-top: var(--space-md);
}

.checkbox-label {
  label {
    font-size: 14px;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  .spinner {
    position: absolute;
    right: var(--space-sm);
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }
}
