.wrapper {
  position: relative;
  width: 100%;
  .spinner {
    position: absolute;
    right: var(--space-sm);
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }
}

.tags {
  display: flex;
  gap: var(--space-sm);
  margin-top: var(--space-sm);
  flex-wrap: wrap;
  .tag {
    display: flex;
    align-items: center;
    gap: 0 var(--space-sm);
    .img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}
