.popup {
  position: fixed;
  z-index: 1111111;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--space-md);
  .popupContent {
    width: 398px;
    max-width: 100%;
    background-color: #fff;
    padding: var(--space-lg);
    border-radius: var(--border-radius-md);
  }
  .header {
    position: relative;
    margin-bottom: var(--space-lg);
    .closeIcon {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding-top: var(--space-md);
    button {
      margin-right: var(--space-md);
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
