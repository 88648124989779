.sideDrawer {
  width: 550px;
  max-width: 100%;
  height: 100vh;
  overflow: auto;
  background: #ffffff;
  position: fixed;
  z-index: 111111;
  top: 0;
  right: 0;
  transition: all 0.8s ease 0s;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.header {
  padding: var(--space-md);
  position: relative;
  border-bottom: 1px solid var(--grey-shade-200);
  text-transform: capitalize;
  .closeIcon {
    position: absolute;
    right: var(--space-md);
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.footer {
  padding: var(--space-md) var(--space-lg);
  display: flex;
  justify-content: flex-end;
  .btnBlock {
    display: flex;
    gap: 0 var(--space-sm);
  }
}
