.main {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-left: auto;
  padding: var(--space-lg);
  padding-bottom: var(--footer-height);
  padding-top: 0;
  transition: all 0.4s ease 0s;
  @media screen and (max-width: 991px) {
    padding: var(--space-lg);
  }
  .container {
    max-width: 100%;
    margin: 0 auto;
    .content {
      padding: var(--space-xl) 0 var(--space-xxl);
    }
  }
}
