.tag {
  display: inline-block;
  border-radius: var(--border-radius-sm);
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;

  &.green {
    color: var(--green-shade);
    background-color: var(--green-shade-light);
  }
  &.grey {
    color: #797979;
    background-color: var(--grey-shade-100);
  }

  &.blue {
    color: #36a9e1;
    background-color: #e9f8ff;
  }

  &.red {
    color: var(--red-shade);
    background-color: var(--red-shade-bg-shade);
  }

  &.small {
    padding: 3px var(--space-esm);
  }

  &.regular {
    padding: 8px var(--space-md);
  }
  &.medium {
    padding: 4px 8px;
  }
  &.large {
    padding: var(--space-sm) var(--space-md);
  }
}
