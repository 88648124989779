.titleSection {
  margin-bottom: var(--space-lg);
  .titleAndTag {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-md);
    .title {
      margin-right: var(--space-md);
    }
    .tag {
      border-left: 1px solid var(--grey-shade-200);
      padding-left: var(--space-md);
      display: flex;
      align-items: center;
      .text {
        margin-right: var(--space-esm);
      }
    }
  }
}
