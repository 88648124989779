$xxl: 1600px;
$xl_2: 1400px;
$xl: 1200px;
$lg: 991px;
$md: 767px;
$sm: 575px;

$nav_for_mbl: $lg;

:root {
  --primary-color: #f26b52;

  --yellow-shade: #ffad0d;
  --green-shade: #058812;
  --green-shade-light: #d1ffd6;

  --purple-shade: #7583ff;

  --red-shade: #ff5151;
  --red-shade-bg-shade: #fff0f0;

  --grey-shade-800: #1f2937;
  --grey-shade-700: #374151;
  --grey-shade-500: #6b7280;
  --grey-shade-400: #9ca3af;
  --grey-shade-300: #d1d5db;
  --grey-shade-200: #e5e7eb;
  --grey-shade-100: #f3f4f6;
  --grey-shade-50: #f9fafb;

  --white-shade: #ffffff;

  --text-color: var(--grey-shade-700);

  --dropdown-icon: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.2666 6.39999L8.08081 9.59999L11.7333 6.44578' stroke='%234C505A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  --dropdown-icon-white: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.2666 6.39999L8.08081 9.59999L11.7333 6.44578' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  --dropdown-icon-primary: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.2666 6.39999L8.08081 9.59999L11.7333 6.44578' stroke='%23f26b52' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  --search-icon: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='11.3333' cy='10.0833' r='7.33333' stroke='%231F2937' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 16.0835L20 20.7502' stroke='%231F2937' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

  --border-radius-sm: 0.333rem;
  --border-radius-md: 0.667rem;

  --space-esm: 0.444rem;
  --space-sm: 0.667rem;
  --space-md: 0.889rem;
  --space-lg: 1.333rem;
  --space-xl: 1.778rem;
  --space-xxl: 4rem;

  --header-height: 4rem;
  --footer-height: 1.778rem;

  --sidebar-width: 280px;

  --sidebar-for-main-width: 280px;

  @media screen and (max-width: 991px) {
    --sidebar-for-main-width: 0;
  }
}
