.profile {
  margin-left: var(--space-lg);
  border-left: 1px solid var(--grey-shade-300);
  position: relative;
  .content {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: right center;
    padding: 0 var(--space-lg);
    background-image: var(--dropdown-icon);
  }
  &.isWhite {
    border-left: 1px solid var(--grey-shade-500);
    .content {
      background-image: var(--dropdown-icon-white);
    }
  }
}

.image {
  padding-right: var(--space-esm);
}
