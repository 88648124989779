.inputBox {
  position: relative;
  input,
  .toggle {
    width: 40px;
    height: 24px;
    display: inline-block;
  }
  input {
    margin: 0;
    z-index: 2;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    &:checked ~ {
      .toggle {
        background-color: var(--primary-color);
        .circle {
          transform: translateX(15px);
        }
      }
    }
  }

  .toggle {
    transition: all 0.4s ease 0s;
    background: var(--grey-shade-300);
    border-radius: 74px;
    position: relative;
    .circle {
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      z-index: 1;
      transition: all 0.4s ease 0s;
    }
  }
}
