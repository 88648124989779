.passwordTerms {
  margin-top: var(--space-md);
  .list {
    display: block;
    margin-bottom: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.5' width='16' height='16' fill='white'/%3E%3Cpath d='M4.00525 4.5L12.0851 12.5799' stroke='%23FB6666' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M3.995 12.59L12.085 4.50002' stroke='%23FB6666' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: var(--space-lg);
    &.checked {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.5' width='16' height='16' fill='white'/%3E%3Cg clip-path='url(%23clip0_73_6996)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.22595 10.4242L12.6444 2.89579C12.6444 2.89579 13.1046 2.15474 13.7741 2.70211C14.2845 3.11474 13.7908 3.73789 13.7908 3.73789L7.26361 13.8432C7.26361 13.8432 6.92888 14.4916 6.34311 14.4916C5.5816 14.4916 5.25524 13.9189 5.25524 13.9189L2.28453 9.43895C2.28453 9.43895 1.53976 8.70632 2.45189 7.94C3.29708 7.23263 4.00838 8.13368 4.00838 8.13368L6.21758 10.4242H6.22595Z' fill='%230DDC60'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_73_6996'%3E%3Crect width='12' height='12' fill='white' transform='translate(2 2.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }
}
