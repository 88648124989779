.page {
    gap: 2rem;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;
        z-index: 99;
    }

    .reporting {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        h2 {
            font-size: 1.5rem;
        }
    }

    .skeleton {

        .cards {
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;
        }

    }
}