.wrapper {
  background-color: #fff;
  border-radius: var(--border-radius-md);
  padding: var(--space-xl);
  box-shadow: 0px 4px 71px rgba(96, 100, 112, 0.13);
  .cities {
    display: flex;
    align-items: center;
    gap: 0 var(--space-md);
  }

  img {
    cursor: pointer;
  }

  .footer {
    display: flex;
    gap: 0 var(--space-lg);
    justify-content: flex-end;
    align-items: center;
    padding-top: var(--space-lg);
  }
}
