.lock {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 11111;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease 0s;
}
