$mbl_res: 991px;

.sidebar {
  width: var(--sidebar-width);
  background: #ffffff;
  border-right: 1px solid var(--grey-shade-200);
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  height: 100vh;
  transition: all 0.4s ease 0s;
  @media screen and (max-width: $mbl_res) {
    transform: translateX(-100%);
  }
  .header {
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-md);
    border-bottom: 1px solid var(--grey-shade-200);
    position: relative;
    .logo {
      width: 129px;
      max-width: 100%;
    }
    .closeIcon {
      position: absolute;
      right: var(--space-md);
      top: 0;
      bottom: 0;
      margin: auto;
      display: none;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: $mbl_res) {
        display: flex;
      }
    }
  }
  .navigation {
    padding: var(--space-lg);
  }
  &.hideOnDesktop {
    transform: translateX(-100%);
  }
  &.active {
    @media screen and (max-width: $mbl_res) {
      transform: translateX(0);
    }
  }
}
