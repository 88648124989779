.header {
  display: flex;
  padding: var(--space-md) var(--space-lg);
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  .details {
    display: flex;
    align-items: center;
  }
  &.loginNav {
    border-bottom: 1px solid var(--grey-shade-200);
    justify-content: space-between;
    z-index: 999;
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 0 var(--space-md);
  margin-right: var(--space-md);
  a {
    &.active {
      color: var(--primary-color);
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 146px;
  }
  .colored {
    display: inline-block;
  }
  .white {
    img {
      display: none;
    }
  }
}

// .menuIcon {
//   border: 1px solid var(--grey-shade-200);
//   background-color: var(--grey-shade-100);
//   border-radius: var(--border-radius-sm);
//   width: 1.778rem;
//   height: 1.778rem;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.bgWhite {
  background-color: var(--grey-shade-800);
  border-bottom: 0 !important;
  .logo {
    .colored {
      display: none;
    }
    .white {
      display: inline-block;
    }
  }
  .nav {
    a {
      color: #fff !important;
      &.active {
        color: var(--primary-color);
      }
    }
  }
}
