.formWrapper {
  .horizontalLabel {
    display: flex;
    align-items: center;
    gap: 0 var(--space-sm);
    > div {
      width: 100%;
    }
    &.notFull {
      > div {
        width: unset;
      }
    }
  }
}

.footer {
  padding-top: var(--space-md);
  border-top: 1px solid var(--grey-shade-200);
  display: flex;
  justify-content: flex-end;
  gap: 0 var(--space-md);
}
