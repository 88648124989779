.nodata {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--space-xxl) 0;
  img {
    width: 100px;
    margin-bottom: var(--space-md);
  }
}
