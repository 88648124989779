.passwordChecker {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-120%);
  .weak {
    color: var(--red-shade);
  }
  .fair {
    color: var(--yellow-shade);
  }
  .good {
    color: var(--purple-shade);
  }
  .excellent {
    color: var(--green-shade);
  }
  .fair,
  .good,
  .excellent {
    display: none;
  }
  .check {
    width: 17px;
    height: 4px;
    background: #d9d9d9;
    display: inline-block;
    margin-right: 4px;
    &.checked {
      background-color: var(--red-shade);
    }
  }
  &.fair {
    .weak,
    .good,
    .excellent {
      display: none;
    }
    .fair {
      display: inline-block;
    }
    .check {
      &.checked {
        background-color: var(--yellow-shade);
      }
    }
  }
  &.good {
    .weak,
    .fair,
    .excellent {
      display: none;
    }
    .good {
      display: inline-block;
    }
    .check {
      &.checked {
        background-color: var(--purple-shade);
      }
    }
  }
  &.excellent {
    .weak,
    .fair,
    .good {
      display: none;
    }
    .excellent {
      display: inline-block;
    }
    .check {
      &.checked {
        background-color: var(--green-shade);
      }
    }
  }
}
