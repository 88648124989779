.wrapper {
  position: relative;
  z-index: 2;
}

.inputWrapper {
  position: relative;
  input {
    padding-left: 2rem !important;
  }
  .color {
    position: absolute;
    left: var(--space-esm);
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.options {
  display: flex;
  flex-wrap: wrap;
  padding: var(--space-esm);
  width: 240px;
  border: 1px solid var(--grey-shade-200);
  border-radius: var(--border-radius-sm);
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + var(--space-esm)));
  z-index: 11;
  background-color: #fff;
  .option {
    width: 16.666%;
    padding: var(--space-esm);
  }
}
