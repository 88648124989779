.spinner {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 111111111;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  // background-color: rgba($color: #fff, $alpha: 1);
  // backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
}
