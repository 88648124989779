.row {
  padding: var(--space-md) var(--space-lg);
  .title {
    margin-bottom: var(--space-esm);
  }
}

.travelerList {
  width: 100%;
  .list {
    display: flex;
    align-items: center;
    gap: 0 var(--space-esm);
    width: calc(100% - 3rem);
    margin-bottom: var(--space-esm);
    &:last-child {
      margin-bottom: 0;
    }
    .img {
      width: 2rem;
      height: 2rem;
    }
  }
}

.approvalRulesList {
  .rule {
    display: flex;
    align-items: center;
    gap: var(--space-esm);
    margin-bottom: var(--space-esm);
    &:last-child {
      margin-bottom: 0;
    }
  }
}
