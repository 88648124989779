.wrapper {
  text-align: center;
  margin-bottom: var(--space-lg);
  padding: 0 var(--space-lg);
  .icon {
    margin-bottom: var(--space-sm);
    img {
      display: inline-block;
    }
  }
  .title {
    margin-bottom: var(--space-md);
  }
}
