.alert {
  width: 100%;
  padding: 0.556rem;
  padding-left: 2.333rem;
  border-radius: var(--border-radius-sm);
  background-repeat: no-repeat;
  background-position: center left 0.556rem;
  background-size: 1.333rem;
  display: flex;
  align-items: center;
  gap: 0 var(--space-sm);
  .close {
    margin-left: auto;
    cursor: pointer;
  }
  &.success {
    background-color: var(--green-shade-light);
  }
  &.error {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%23FF5151'/%3E%3Cpath d='M8.00525 8L16.0851 16.0799' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3Cpath d='M7.995 16.09L16.085 8' stroke='white' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-color: var(--red-shade-bg-shade);
  }
  &.fixed {
    padding: var(--space-md);
    padding-left: 2.333rem;
    position: fixed;
    top: var(--space-lg);
    right: var(--space-lg);
    z-index: 11111111111;
    width: 300px;
    max-width: 100%;
    transform: translateX(200%);
    transition: all 0.4s ease 0s;
    &.active {
      transform: none;
    }
  }
  p {
    word-break: break-all !important;
  }
}
