.text {
  font-style: normal;
  font-weight: 400;
  line-height: 1.14;
  color: var(--grey-shade-700);
  &:is(h1, h2, h3, h4, h5) {
    color: var(--grey-shade-800);
  }
  &:is(h1) {
    font-weight: 600;
    font-size: 1.334rem;
    line-height: 1.3;
  }
  &:is(h2) {
    font-weight: 600;
    font-size: 1.111rem;
    line-height: 1.4;
  }
  &:is(h3) {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.4;
  }
  &:is(h4) {
    font-weight: 600;
    font-size: 0.889rem;
    line-height: 1.4;
  }
  &:is(h5) {
    font-weight: 600;
    font-size: 0.778rem;
    line-height: 1.4;
  }
  &:is(h6) {
    font-weight: 600;
    font-size: 0.667rem;
    line-height: 1.4;
    color: var(--grey-shade-500);
  }
}
.isWhite {
  color: var(--white-shade) !important;
}
.smallSize {
  font-size: 0.667rem;
}
.regularSize {
  font-size: 0.778rem;
}
.mediumSize {
  font-size: 0.889rem;
}
.largeSize {
  font-size: 1.333rem;
  color: var(--grey-shade-800);
}

.lightWeight {
  font-weight: 300;
}
.regularWeight {
  font-weight: 400;
}
.mediumWeight {
  font-weight: 500;
}
.semiboldWeight {
  font-weight: 500;
}
.boldWeight {
  font-weight: 700;
}

.errorMessage {
  color: var(--red-shade);
}
