.balance {
    background-color: #f9fafb;
    border-radius: 10px;
    border: 1px solid #d1d5db;
    padding: 2rem;

    h2 {
        font-size: 1.5rem;
    }

    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .right {
            display: flex;
            gap: 1rem;
            flex-direction: column;
        }
    }

    .number {
        color: #f26b52;
        font-size: 1.2rem;
        font-weight: 700;
    }

    .right {
        text-align: end;
        gap: 1rem;
        display: flex;
        flex-direction: column;
    }
}