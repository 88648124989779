.wrapper {
  margin-bottom: var(--space-lg);

  .header {
    display: flex;
    align-items: center;
    gap: 0 var(--space-sm);
  }

  .title {
    margin: var(--space-md) 0;
  }
}

.buttongroup {
  display: flex;
  gap: 0.5rem;
}

// 

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  /* Ensure it covers the content */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  /* Ensure it is above the overlay */
}