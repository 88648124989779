.wrapper {
  padding: var(--space-md) var(--space-lg);
  .heading {
    margin-bottom: var(--space-md);
  }
  .content {
    display: flex;
    margin-bottom: var(--space-lg);
    .list {
      width: 50%;
    }
  }
  .flight {
    margin-bottom: var(--space-md);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .flightList {
    margin-bottom: var(--space-md);
    &:last-child {
      margin-bottom: 0;
    }
  }
}
