.accordion {
  width: 100%;
  .head {
    position: relative;
    padding-right: var(--space-lg);
    .dropDown {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      cursor: pointer;
      transform: rotate(180deg);
      transition: all 0.4s ease 0s;
      width: 1.334rem;
      height: 1.334rem;
    }
  }
  &.open {
    .dropDown {
      transform: rotate(0);
    }
  }
  &.right {
    .head {
      display: flex;
      justify-content: flex-end;
    }
  }
}
