.calendarContainer {
    // position: absolute;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 999;
}

.dateRangeWrapper {
    position: absolute;
    transform: translate(-25%, 0%);
    margin-top: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    right: 0;

    .range {
        box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }

    .dateButton {
        background-color: #f26b52;
        border-color: #f26b52;
        box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 768px) {
    .calendarContainer {
        max-width: 90%;
    }

    .dateRangeWrapper {
        width: fit-content;
        transform: translate(-10%, -0%);
    }
}