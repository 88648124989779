$sidebar: 250px;

.main {
  display: flex;

  .sidebar {
    width: $sidebar;
  }

  .results {
    width: calc(100% - $sidebar);
    padding-left: var(--space-xl);

    .skeleton {
      margin-bottom: 2rem;
    }
  }
}