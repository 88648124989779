.link {
  text-decoration-line: underline;
  color: var(--primary-color);
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  &.small {
    font-weight: 600;
    font-size: 0.667rem;
    line-height: 1.5;
  }
}
