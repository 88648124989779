table {
  width: 100%;
  border-spacing: 0;

  thead {
    background: var(--grey-shade-100);

    tr th:first-child {
      border-radius: var(--border-radius-sm) 0 0 var(--border-radius-sm);
      padding-left: var(--space-md);

    }

    tr th:last-child {
      border-radius: 0 var(--border-radius-sm) var(--border-radius-sm) 0;
      padding-left: var(--space-md);

    }

    tr th {
      padding: 10px var(--space-esm);
      text-align: left;
      width: 33%;

      h5 {
        font-weight: 600;
        font-size: 0.778rem;
        line-height: 1.4;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: var(--space-md) var(--space-esm);
        border-bottom: 1px solid var(--grey-shade-200);
        font-weight: 400;
        font-size: 0.778rem;
        line-height: 1.4;
        color: var(--grey-shade-800);
      }
    }
  }
}

.buttongroup {
  gap: 1rem;
  display: flex;
}


.title {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  /* Ensure it covers the content */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  /* Ensure it is above the overlay */
}