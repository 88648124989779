.card {
  border: 1px solid var(--grey-shade-200);
  border-radius: var(--border-radius-md);
  &.largePadding {
    padding: 3.111rem;
    @media screen and (max-width: $md) {
      padding: 15px;
    }
  }
  &.mediumPadding {
    padding: var(--space-lg);
  }
  &.regularPadding {
    padding: var(--space-md);
  }
}
