.flightRow {
  display: flex;
  .logo {
    width: 15%;
    img {
      width: 3.111rem;
      height: 3.111rem;
      object-fit: cover;
    }
  }
  .flight {
    width: 70%;
  }
  .otherDetails {
    width: 15%;
    text-align: right;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .luggage {
      display: flex;
      align-items: center;
      margin-bottom: var(--space-esm);
      img {
        margin-left: var(--space-esm);
        width: 0.889rem;
      }
    }
  }
}

.row {
  margin-bottom: var(--space-md);
}

.summary {
  margin-top: 68px;
}
