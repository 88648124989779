.pagination {
  display: flex;
  margin-top: var(--space-md);
  justify-content: flex-end;
  button {
    background: #ffffff;
    border: 1px solid var(--grey-shade-200);
    border-radius: var(--border-radius-sm);
    width: 1.778rem;
    height: 1.778rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    color: var(--grey-shade-800);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    cursor: pointer;
    &:disabled {
      background-color: var(--grey-shade-200);
      cursor: not-allowed;
    }
  }
}
