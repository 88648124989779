.wrapper {
  .from {
    text-align: left;
  }

  .to {
    text-align: right;
  }

  .city,
  .details {
    display: flex;
    justify-content: space-between;
  }

  .details {
    position: relative;
    padding-top: var(--space-esm);
  }

  .duration {
    // transform: translateY(80%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 var(--space-sm);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    .stop {
      border-left: 1px solid var(--grey-shade-300);
      padding-left: var(--space-sm);
      color: var(--red-shade) !important;
    }
  }

  .city {
    position: relative;

    .to,
    .from {
      background-color: #fff;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--grey-shade-200);
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateY(3px);
      }
    }

    .from {
      padding-right: var(--space-md);

      &::before {
        right: 0;
      }
    }

    .to {
      padding-left: var(--space-md);

      &::before {
        left: 0;
      }
    }

    .icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 1.334rem;
      gap: 0 var(--space-lg);

      .text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        transform: translateY(100%);
      }

      &::before {
        content: "";
        width: 100%;
        height: 1.5px;
        background-color: var(--grey-shade-200);
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      img {
        position: relative;
        display: inline-block;
        transform: translateY(-1.5px);
      }

      .tooltip {
        display: none;
        width: 180px;
        border: 1px solid var(--grey-shade-200);
        background-color: #fff;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-37%, -100%);
        padding: var(--space-esm);
        border-radius: var(--border-radius-sm);
        text-align: center;
        align-items: center;
        justify-content: center;
        gap: 0 var(--space-esm);

        &::before {
          content: "";
          width: 10px;
          height: 10px;
          background-color: #fff;
          border: 1px solid var(--grey-shade-200);
          position: absolute;
          bottom: -5px;
          left: 0;
          right: 0;
          margin: auto;
          transform: rotate(45deg);
          border-width: 0 1px 1px 0;
        }

        .cityName {
          opacity: 0.6;
          padding-left: var(--space-esm);
          border-left: 1px solid var(--grey-shade-300);
        }
      }

      .iconList {
        position: relative;
        min-width: 50px;
        text-align: center;

        &:hover {
          .tooltip {
            display: flex;
          }
        }
      }
    }
  }

  .duration {
    p {
      color: var(--grey-shade-700) !important;
    }
  }
}