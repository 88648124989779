.profiles {
  display: flex;
  align-items: center;
  img,
  .img {
    margin-left: -8px !important;
    border: 2px solid #fff;
    &:first-child {
      margin-left: 0;
    }
  }
}
