.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background: var(--grey-shade-50);
  border: 0;
}

.react-datepicker-popper[data-placement^="bottom"] {
  z-index: 11;
}

.react-datepicker {
  border: 1px solid var(--grey-shade-200);
  border-radius: var(--border-radius-md);
  overflow: hidden;
  select {
    background-color: var(--grey-shade-50);
    border: 1px solid var(--grey-shade-200);
    border-radius: var(--border-radius-sm);
    padding: 0.2rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--grey-shade-800);
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 600;
  font-size: 0.778rem;
  line-height: 1.4;
  color: var(--grey-shade-800);
  display: none;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 0.778rem;
  color: var(--grey-shade-800);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  color: #fff;
  background-color: var(--primary-color);
}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  background-image: var(--dropdown-icon);
  border: 0;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  opacity: 0.6;
  width: 14px;
  height: 14px;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(-90deg);
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
