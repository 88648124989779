.wrapper {
  position: relative;
  width: 100%;
  .spinner {
    position: absolute;
    right: var(--space-sm);
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
  }
}
