.dropDown {
  background-color: #fff;
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--grey-shade-200);
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  width: 400px;
  max-height: 292px;
  overflow-y: auto;
  @media screen and (max-width: 991px) {
    position: fixed;
    left: 0;
    top: var(--header-height);
    max-height: unset;
    height: 100vh;
    width: 100%;
    transform: translateY(0);
  }
  .notificationList {
    .list {
      cursor: pointer;
      .container {
        border-bottom: 1px solid var(--grey-shade-200);
        display: flex;
        align-items: center;
        padding: var(--space-md) 0;
      }
      padding: 0 var(--space-lg);
      padding-right: var(--space-xl);
      &:last-child {
        border-bottom: 0;
      }
      .icon {
        width: 2.222rem;
        height: 2.222rem;
        background-color: var(--grey-shade-100);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .content {
        padding-left: var(--space-sm);
        width: calc(100% - 2.222rem);
        .title {
          margin-bottom: 4px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .footer {
          display: flex;
          justify-content: space-between;
        }
      }
      &.unread {
        background-color: var(--grey-shade-50);
        position: relative;
        &::after {
          content: "";
          width: 8px;
          height: 8px;
          background-color: var(--primary-color);
          border-radius: 50%;
          position: absolute;
          right: var(--space-md);
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
