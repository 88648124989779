.btn {
  display: inline-block;
  min-height: 2.667rem;
  text-decoration: none;
  padding: var(--space-sm) var(--space-md);
  text-align: center;
  border-radius: var(--border-radius-md);
  font-size: 0.778rem;
  font-weight: 600;
  line-height: 1.5;
  min-width: 100px;
  transition: all 0.4s ease 0s;
  outline: none;
  border: 0;
  cursor: pointer !important;

  &.primary {
    background: var(--primary-color);
    color: var(--white-shade);
  }

  &.medium {
    padding: var(--space-sm) var(--space-md);
  }

  &.secondary {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);

    &.hasIcon {
      &.dropdown {
        background-image: var(--dropdown-icon-primary);
      }
    }
  }


  &.hasIcon {
    background-repeat: no-repeat;
    background-position: center right var(--space-sm);
    padding-right: var(--space-xl);

    &.dropdown {
      background-image: var(--dropdown-icon-white);
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.fitWidth {
    min-width: 200px;
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    cursor: not-allowed !important;

    &:active {
      transform: none;
    }
  }
}