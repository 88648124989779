.formWrapper {
  .horizontalLabel {
    display: flex;
    align-items: center;
    gap: 0 var(--space-sm);
    > div {
      width: 100%;
    }
    &.notFull {
      > div {
        width: unset;
      }
    }
  }
}

.footer {
  padding-top: var(--space-md);
  border-top: 1px solid var(--grey-shade-200);
  display: flex;
  justify-content: flex-end;
  gap: 0 var(--space-md);
}

.addRule {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-esm);
  background-color: #fff2f0;
  border-radius: var(--border-radius-sm);
  padding: var(--space-sm);
  cursor: pointer;
  * {
    color: var(--red-shade) !important;
  }
}
