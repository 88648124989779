.dropdown {
  background-color: #fff;
  border: 1px solid var(--grey-shade-200);
  border-radius: var(--border-radius-md);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  z-index: 11;
  max-height: 200px;
  overflow-y: auto;
  .list {
    padding: var(--space-sm) var(--space-md);
    cursor: pointer;
    &:hover {
      background-color: var(--grey-shade-100);
    }
    &.disable {
      pointer-events: none;
      p {
        color: var(--grey-shade-400) !important;
      }
    }
  }
  .cta {
    padding: var(--space-sm) var(--space-md);
  }
}
