.dropDown {
  width: 180px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-100%, 100%);
  background: #ffffff;
  border: 1px solid var(--grey-shade-200);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: var(--border-radius-sm);
  padding: var(--space-esm) 0;
  button {
    display: flex;
    align-items: center;
    width: 100%;
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    padding: var(--space-esm) var(--space-md);
    margin: 0;
    text-decoration: none;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      margin-right: var(--space-esm);
    }
    &.delete {
      color: var(--red-shade);
    }
    &:hover {
      background-color: var(--grey-shade-100);
    }
  }
}
