.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: var(--footer-height);
  display: flex;
  z-index: 111;
  align-items: center;
  justify-content: center;
  gap: 0 var(--space-lg);
  background: #ffffff;
  box-shadow: 0px 4px 71px rgba(96, 100, 112, 0.13);
  .group {
    padding-right: var(--space-lg);
    border-right: 1px solid var(--grey-shade-200);
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
}
