.wrapper {
  position: relative;
}

.tags {
  margin-top: var(--space-esm);
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-esm);
  .tag {
    display: flex;
    align-items: center;
    gap: 0 var(--space-esm);

    .close {
      cursor: pointer;
    }
  }
}
