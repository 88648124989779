.link {
  text-decoration-line: underline;
  &.small {
    font-weight: 600;
    font-size: 0.667rem;
    line-height: 1.5;
  }
  &.primary {
    color: var(--primary-color);
  }
  &.secondary {
    color: var(--grey-shade-800);
    text-decoration: none;
  }
}
