.image {
  border-radius: 50%;
  background: #ffeae6;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #1f2a44;
  &.regular {
    font-weight: 600;
    font-size: 0.667rem;
    line-height: 1;
  }
  &.large {
    font-weight: 700;
    font-size: 1.111rem;
    line-height: 1;
  }
}
