/* -------------------------------------------
Variables
------------------------------------------- */
@import "./variables.scss";

/* -------------------------------------------
Fonts
------------------------------------------- */
@import "./fonts/muli.scss";

/* -------------------------------------------
Grid
------------------------------------------- */
@import "./grid/css-grid.scss";

html {
  font-size: 18px;
  max-width: 100%;
  overflow-x: hidden;

  @media screen and (max-width: $xl) {
    font-size: 16px;
  }

  @media screen and (max-width: $md) {
    font-size: 14px;
  }
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Muli" !important;
  background-color: var(--white-shade);
  color: var(--text-color);
  max-width: 100%;
  overflow-x: hidden;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: "Muli" !important;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

/* -------------------------------------------
Components
------------------------------------------- */
@import "./components/card.scss";
@import "./components/slider.scss";
@import "./components/datePicker.scss";

.react-datepicker-wrapper {
  width: 100%;
  display: block;
}

.btn-group {
  background-color: #e9e9e9;
  padding: 4px;
  border-radius: 0.25rem;

  .btn {
    background-color: #e9e9e9;
    color: black;
    border: none;
    font-size: 12px;
    border-radius: 0.25rem;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
}

.active.btn {
  background-color: #fff;
  font-weight: 700;
  border: none;
  color: black;
  font-size: 12px;
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.dropdown-toggle.btn.btn-primary {
  background-color: #f26b52;
  border-color: #f26b52;
}

.disabled-btn {
  background-color: rgb(177, 177, 177) !important;
  border-color: rgb(177, 177, 177) !important;
  color: white !important;
  cursor: not-allowed;
}

.form-check-input:checked {
  background-color: #f26b52;
  border-color: #f26b52;
}

.form-check-label {
  font-size: 12px;
}