.tabs {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--grey-shade-200);
  margin-bottom: var(--space-lg);
  text-align: center;
  .tab {
    padding: var(--space-sm) var(--space-md);
    .click {
      cursor: pointer;
      display: inline-block;
    }
    .description {
      opacity: 0.6;
    }
    &.active {
      border-bottom: 1px solid var(--primary-color);
      .title {
        color: var(--primary-color);
      }
      .description {
        font-weight: 500;
        opacity: 1;
      }
    }
  }
}
