.img {
  border-radius: 50%;
  object-fit: cover;
  display: inline-block;
  &.small {
    width: 1.334rem;
    height: 1.334rem;
  }
  &.regular {
    width: 1.778rem;
    height: 1.778rem;
  }
  &.medium {
    width: 2.222rem;
    height: 2.222rem;
  }
  &.large {
    width: 6rem;
    height: 6rem;
  }
}
